/* eslint max-statements:0 */
/* eslint max-depth:0 */
import PropTypes from "prop-types";
import React from "react";
import { FormCheck as BsRadio } from "react-bootstrap";




class RadioBoxLegend extends React.Component {

  constructor(props) {

    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      active : null
    }
  
  }

  normalizeOptions() {
    const { children} = this.props
    
    return React.Children.map(children, ({ props }) => {

      let label

      if (props.label) {
        label = props.label
      } else {
        label = props.children
      }

      return {
        value: props.value == null ? props.children : props.value,
        label,
        disabled: Boolean(props.disabled)
      }
    })
  }


  handleChange(value) {
    const { onChange } = this.props
    
    this.setState({ active: parseInt(value) })

    if (!onChange) return

    onChange(value)
  }
    

  render() {
    const { name, legend } = this.props
    const { active } = this.state
    const options = this.normalizeOptions()

    const toRender = options.map((item, key) => {
      let RBClassName = 'juloa-default-group form-check-image'
      const isActive = active === item.value
      RBClassName += isActive ? 'juloa-default-group form-check-image checked' : ''
      return (
        <div
          className={RBClassName}
          key={`legend-${key}`}
        >
            <div
              onClick={() => this.handleChange(item.value)}
            >
              <BsRadio
                type='radio'
                label=''
                value={item.value}
                isValid
                checked={isActive}
                onChange={null}
                id={`legend-${key}`}
                name={`${name}`}
                className="bsradio"
              />
              <img src={`img/icons/categorie${key}.svg`} key={`header${key}`} />
              <label type='checkbox'>{item.label}</label>
            </div>
          <p className="form-check-legend">
            {legend[key]}
          </p>
        </div>
      )
    }
      )
      
    return (
    <div className="form-check-images">
    {toRender}
    </div>
    )
  }
}

RadioBoxLegend.propTypes = {
value: PropTypes.any,
onChange: PropTypes.func,
disabled: PropTypes.bool,
readOnly: PropTypes.bool,
children: PropTypes.node,
options: PropTypes.array,
style: PropTypes.object,
required: PropTypes.bool
}

export default RadioBoxLegend


