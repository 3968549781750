import PropTypes from "prop-types";
import React from "react";
/* eslint-disable react/jsx-no-bind */
import FilesList from './FilesList';
import { withYoo } from "../../YooForm";

class FileField extends React.PureComponent {
  state = {
    files: {}
  }

  handleChange = async evt => {
    const files = evt.target.files;
    const { onChange, disableSubmit, multiple, max_size, tooBigMsg, langs } = this.props;
    const fileName = files.item(0).name;
    let file_extension = fileName.split('.').pop();
    if(this.props.allowed_extensions.indexOf(file_extension) === -1){
      alert(`${file_extension} ${langs.extensions}`);
      return false
    }

    if (files.length) {
      if (max_size && files.item(0).size > max_size) {
        alert(tooBigMsg + Math.round(max_size * 10 / 1024 / 1024) / 10 + "M");
      } else {
        if (!multiple) {
          Object.keys(this.state.files).map(e => this.state.files[e]).forEach(file => this.deleteFile(file.filename));
          this.setState({ files: {} });
        }
        let filenames = []
        let filenamesSubmit = []
        let uploadingFiles = [];

        for (let i = 0; i < files.length; i++) {
          filenames.push(files.item(i).name)
          uploadingFiles.push(this.sendFile(files.item(i)));
        }

        if (disableSubmit) {
          disableSubmit({ filenames, deleteFiles: false });
        }

        const uploadedFiles = await Promise.all(uploadingFiles);
        
        filenames = []

        Object.keys(this.state.files).map(e => this.state.files[e]).forEach(file => {
          if (file.filename) {
            filenames.push(file.filename)
          }
        });

        uploadedFiles.forEach((file, i) => {
          const name = files.item(i).name;
          filenames.push(file.filename);
          filenamesSubmit.push(name);

          this.setState({
            files: {
              ...this.state.files,
              [name]: {
                ...this.state.files[name],
                mimeType: files.item(i).type,
                filename: file.filename,
              },
            },
          });
        });
        if (disableSubmit) {
          disableSubmit({ filenames: filenamesSubmit, deleteFiles: true });
        }
        if (onChange) {
          onChange(filenames);
        }
      }
    }
  }

  sendFile = async file => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.files };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ files: copy });
        } else {
          console.log("not computable");
        }
      });

      req.upload.addEventListener("load", event => {
        const { files } = this.state;
        const copy = { ...files };

        copy[file.name] = { state: "done", percentage: 100 };
        if (files[file.name] && files[file.name].isToRemove) {
          this.deleteFile(file.name);
          return;
        }
        this.setState({ files: copy });
      });

      req.addEventListener("load", function () {
        resolve(JSON.parse(this.response));
      })

      req.upload.addEventListener("error", () => {
        const copy = { ...this.state.files };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ files: copy });
        reject(req.response);
      });

      const url = 'api.php?task=saveFile';
      const formData = new FormData();

      formData.append("file", file);

      req.open("POST", url);
      req.send(formData);
    });
  }

  removeFile = filename => () => {
    const { files: { [filename]: fileToRemove, ...files } } = this.state;
    if (fileToRemove && fileToRemove.state === 'done') {
      this.setState({ files });
      this.deleteFile(fileToRemove.filename);
      if (this.props.onChange) {
        const filenames = []
        Object.keys(this.state.files).map(e => this.state.files[e]).forEach(file => {
          if (file.filename !== fileToRemove.filename) {
            filenames.push(file.filename)
          }
        });
        this.props.onChange(filenames);
      }

    } else {
      this.setState({
        files: {
          [filename]: { ...fileToRemove, isToRemove: true },
          ...files
        },
      });
    }
  }

  deleteFile = async filename => {
    const url = 'api.php?task=deleteFile';
    const formData = new FormData();

    formData.append('filename', filename);
    await fetch(url, { method: 'POST', body: formData });
  }

  render() {
    const { handleChange, removeFile } = this;
    const { files } = this.state;
    const { multiple, allowed_extensions, placeholder } = this.props;

    return (
      <div>
        <div className="fileUploader">
          <div className="before">{placeholder}</div>
          <input
            type="file"
            accept={allowed_extensions}
            className="file-upload toto"
            onChange={handleChange}
            multiple={multiple}
          />
        </div>
        <div className="fileUploaderContainer">
          <FilesList files={files} removeFile={removeFile} />
        </div>
      </div>
    )
  }
}

FileField.propTypes = {
  multiple: PropTypes.bool,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  allowed_extensions: PropTypes.string,
  tooBigMsg: PropTypes.string
}

FileField.defaultProps = {
  multiple: true
};

export default withYoo(FileField)
