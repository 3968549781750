import React from "react";

const SvgProject = props => (
<svg width="1em" height="1em" viewBox="0 0 71 87" {...props}>
  <g fill="none" fillRule="evenodd">
    <path
      d="M31.921 62.524c.103-.013.208-.02.314-.02h-6.802a4.062 4.062 0 0 1-.844-8.036h11.988c.232 0 .456-.03.67-.088h29.69a4.062 4.062 0 0 1 0 8.124H50.885c.106 0 .211.007.314.02H31.921zm-21.152 0H9.06c-.548 0-1.056.17-1.473.462a2.344 2.344 0 0 1 .411-4.651h1.325a2.344 2.344 0 0 1 1.445 4.189zM2.86 44.478h22.68a2.29 2.29 0 0 1 0 4.58H2.86a2.29 2.29 0 0 1 0-4.58zM35.655 39h31.4a2.29 2.29 0 0 1 0 4.58h-31.4a2.29 2.29 0 0 1 0-4.58zM2.819 73.753h32.464a2.344 2.344 0 1 1 0 4.688H2.819a2.344 2.344 0 0 1 0-4.688zm22.985 9.717H48.3a1.538 1.538 0 0 1 0 3.075H25.804a1.538 1.538 0 1 1 0-3.075zm-.435-15.146h18.06a2.344 2.344 0 0 1 0 4.687h-18.06a2.344 2.344 0 1 1 0-4.687z"
      fill="#E7E7E7"
      opacity={0.4}
    />
    <path
      d="M7.417 66.189c1.919 1.444 6.29.852 13.115-2.158a97.118 97.118 0 0 0 4.436-2.099c.874-.439 9.764-5.187 12.249-6.309 4.161-1.879 7.844-2.895 11.846-3.089l-8.74-15.48a5.941 5.941 0 0 1-.768-2.92v-3.886c0-.71.577-1.284 1.287-1.284.711 0 1.288.575 1.288 1.284v3.886c0 .582.15 1.154.436 1.66l15.62 27.664a9.98 9.98 0 0 1-.085 10.045c-1.834 3.125-5.11 4.997-8.743 4.997H14.642c-3.633 0-6.909-1.872-8.743-4.997a9.98 9.98 0 0 1-.085-10.045 1.289 1.289 0 0 1 1.754-.489c.619.348.838 1.131.49 1.749a7.69 7.69 0 0 0-.64 1.47zm-.323 2.75a7.517 7.517 0 0 0 1.026 3.267c1.373 2.337 3.803 3.726 6.522 3.726h34.716c2.719 0 5.15-1.389 6.522-3.726a7.417 7.417 0 0 0 .063-7.488l-5.454-9.66c-4.172.002-7.88.937-12.222 2.898-2.393 1.08-11.212 5.79-12.154 6.263a99.648 99.648 0 0 1-4.551 2.153C14.96 69.284 10.137 70.22 7.094 68.94zM21.87 34.13V20.933a5.06 5.06 0 0 1-2.526-4.374c0-2.79 2.277-5.059 5.077-5.059h15.158c2.8 0 5.077 2.268 5.077 5.06a5.06 5.06 0 0 1-2.526 4.373v4.508c0 .709-.577 1.284-1.288 1.284-.71 0-1.287-.575-1.287-1.284v-6.23l.858-.302a2.498 2.498 0 0 0 1.669-2.35 2.501 2.501 0 0 0-2.503-2.491H24.421a2.501 2.501 0 0 0-2.503 2.491c0 1.061.678 2 1.67 2.35l.857.302v14.92a5.941 5.941 0 0 1-.772 2.927L10.84 59.723a1.289 1.289 0 0 1-1.754.486 1.282 1.282 0 0 1-.488-1.75l12.835-22.664a3.378 3.378 0 0 0 .438-1.665z"
      fill={props.color2}
    />
    <path
      d="M13.44 0a1.44 1.44 0 1 0 0 2.88 1.44 1.44 0 0 0 0-2.88zm19.55 35.5a1.44 1.44 0 1 0 .002 2.88 1.44 1.44 0 0 0-.001-2.88zm-18.05-3a1.44 1.44 0 1 0 0 2.88 1.44 1.44 0 0 0 0-2.88zm23.628 11.276a4.143 4.143 0 0 0-4.138 4.138 4.143 4.143 0 0 0 4.138 4.137 4.143 4.143 0 0 0 4.138-4.137 4.143 4.143 0 0 0-4.138-4.138zm0 5.517a1.38 1.38 0 0 1-1.38-1.38 1.38 1.38 0 0 1 2.759 0c0 .761-.619 1.38-1.38 1.38zm25.46-8.668a1.25 1.25 0 0 0-1.164 2.212l3.52 1.85c.916.483 1.988-.296 1.813-1.317l-.561-3.271 2.376-2.317c.742-.723.333-1.983-.692-2.132l-3.285-.477-1.468-2.976c-.459-.93-1.784-.93-2.242 0l-1.47 2.976-3.283.477c-1.026.15-1.435 1.41-.693 2.132l2.376 2.317-.264 1.544a1.25 1.25 0 0 0 2.464.422l.376-2.191a1.25 1.25 0 0 0-.36-1.107l-1.033-1.007 1.428-.208c.407-.059.759-.314.94-.683l.64-1.295.638 1.295c.182.369.534.624.941.683l1.429.208-1.034 1.007a1.25 1.25 0 0 0-.36 1.107l.245 1.422-1.278-.671zM20.04 71c-.759 0-1.374.644-1.374 1.44 0 .794.615 1.439 1.374 1.439h27.476c.759 0 1.374-.645 1.374-1.44 0-.795-.615-1.439-1.374-1.439H20.041z"
      fill={props.color1}
    />
  </g>
</svg>
);

export default SvgProject;