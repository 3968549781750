export const required = value => value != null && String(value).length > 0

export const minLength = min => value => Boolean(value && value.length >= min)

export const maxLength = max => value => Boolean(!value || (value && value.length <= max))

export const type = typeName => (value) => {
  if (typeName === "array") return Array.isArray(value)
  else if (value === null || value === undefined) return true
  else return typeof value === typeName
}

export const instance = Construct => value => value instanceof Construct

export const slug = value => /^[\w-.]+$/.test(value)

export const email = value => /^[\w.!#$%&’*+/=?^_`{|}~-]+@[\w-]+(?:\.[\w-]+)*$/.test(value)

export const id = value => (value == null || typeof value === "string")

export const alphaNum = (additionalChars = "") => {

  const accents = "áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ"

  const regex = new RegExp("^[\\w" + accents + additionalChars + ". ]*$")

  return value => regex.test(value)
}

export const url = value => /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i.test(value)

