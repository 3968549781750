/* eslint max-statements:0 */
/* eslint max-depth:0 */
import PropTypes from "prop-types";
import React from "react";
import { FormCheck as BsRadio } from "react-bootstrap";
import { Col, Row} from 'react-bootstrap';
import Lightbox from "react-image-lightbox";



class RadioBox extends React.Component {

  constructor(props) {

    super(props)
    this.handleChange = this.handleChange.bind(this)
    const { options, value } = props
    this.state = {
      imagePopup: '',
      isOpen: false
    };
  }

  normalizeOptions() {
    const { children } = this.props
    
    return React.Children.map(children, ({ props }) => {

      let label

      if (props.label) {
        label = props.label
      } else {
        label = props.children
      }

      return {
        value: props.value == null ? props.children : props.value,
        label,
        disabled: Boolean(props.disabled)
      }
    })
  }


  handleChange(e) {
    const value = e && e.target ? e.target.value : e
    const { onChange, showDiv } = this.props

    if (!onChange) return

    onChange(value)
    showDiv(value)
  }
    

  render() {
    const { name, mode, langs } = this.props
    const options = this.normalizeOptions()
    const { imagePopup, isOpen } = this.state;
    
    const toRender = options.map((item, key) => (
      <BsRadio
        inline
        type='radio'
        label={item.label}
        id={item.label.key}
        name={`${name}`}
        key={`radio-${key}`}
        className="custom-radiobox juloa-checkbox"
        onChange={this.handleChange}
        value={item.value}
      />
    ))

    const toRenderImage = options.map((item, key) => (
          <Col lg={4} md={12} className="bloc-wrap wow fadeIn">
            <label className="bloc-header">
              <img src={`img/header${item.value}.png`} key={`header${key}`} />
              <div className="tools" key={`img${key}`}>
                <BsRadio
                  inline
                  type='radio'
                  label=''
                  id={`box-${key}`}
                  name={`${name}`}
                  key={`box-${key}`}
                  className="custom-radiobox juloa-checkbox"
                  onChange={this.handleChange}
                  value={item.value}
                />
                <a className="btn-header" onClick={() => this.setState({ isOpen: true, imagePopup: `img/header${item.value}.png` })}>
                  {langs.seeImg}
                </a>
                {isOpen && (
                  <Lightbox
                    mainSrc={imagePopup}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                  />
                )}
              </div>
            </label>
          </Col>
    ))

      return (
        mode === "image" ?
          <div className="formCommun">
            <Row className="list-img">
              {toRenderImage}
            </Row>
          </div>
        :
        toRender
      )
  }

}

RadioBox.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  children: PropTypes.node,
  options: PropTypes.array,
  style: PropTypes.object,
  required: PropTypes.bool
}

export default RadioBox
