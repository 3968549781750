import React from "react";

const SvgContact = props => (
    <svg width="1em" height="1em" viewBox="0 0 95 77" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
            d="M54.427 58.822a1.692 1.692 0 0 0-.21-.013H92.11a2.722 2.722 0 0 0 0-5.445H71.181a1.73 1.73 0 0 1-.449.059H46.594a1.73 1.73 0 0 1-.448-.06H20.143a2.722 2.722 0 0 0 0 5.446h9.744c-.071 0-.142.004-.21.013h24.75zm7.999 3.455h-51.17c-.222 0-.434-.041-.628-.118H2.57a1.57 1.57 0 1 0 0 3.142h59.257a1.57 1.57 0 0 0 .598-3.024zM35.207 45.111h-9.474a1.535 1.535 0 1 0 0 3.07h9.474a1.535 1.535 0 0 0 0-3.07zM88.57 66.348H48.227a1.57 1.57 0 1 0 0 3.141H88.57a1.57 1.57 0 1 0 0-3.141zm-37.957 4.136H22.744a1.03 1.03 0 0 0 0 2.061h27.87a1.03 1.03 0 1 0 0-2.061zM61.273 45h-3.896a1.57 1.57 0 0 0 0 3.141h3.897a1.57 1.57 0 1 0 0-3.141z"
            fill="#E7E7E7"
            opacity={0.4}
            />
            <path
            d="M72.717 61.279c.528.306.937.759 1.216 1.317.303.607.4 1.186.4 1.63v8.576c0 1.43-.684 2.455-1.763 2.994-.607.304-1.186.4-1.63.4H65a1.25 1.25 0 1 1 0-2.5h5.938c.091 0 .316-.037.513-.136.26-.13.381-.31.381-.758v-8.575c0-.091-.037-.316-.136-.513-.13-.26-.311-.381-.758-.381a1.25 1.25 0 0 1-.359-.052 35.508 35.508 0 0 1-23.224 8.628 35.414 35.414 0 0 1-23.22-8.63 1.25 1.25 0 0 1-.363.054c-.091 0-.316.037-.513.136-.26.13-.38.31-.38.758a1.25 1.25 0 1 1-2.5 0c0-1.374.631-2.375 1.64-2.93-6.318-6.417-10.216-15.224-10.216-24.94C11.804 16.72 27.72.803 47.356.803c16.592 0 30.87 11.457 34.612 27.396.35 1.489.603 3.004.759 4.537a35.956 35.956 0 0 1-.794 11.925 35.548 35.548 0 0 1-9.216 16.617zM14.304 36.356c0 18.254 14.798 33.053 33.052 33.053 13.45 0 25.403-8.11 30.506-20.307a32.789 32.789 0 0 0 2.346-9.086 33.43 33.43 0 0 0-.674-11.245C76.056 13.956 62.781 3.304 47.356 3.304c-18.254 0-33.052 14.798-33.052 33.052zm60.298 6.773a1.25 1.25 0 0 1 2.31.957 25.022 25.022 0 0 1-2.572 4.704 1.25 1.25 0 0 1-2.052-1.428 22.337 22.337 0 0 0 2.314-4.233zm-4.614 7.03a1.25 1.25 0 1 1 1.8 1.735c-5.776 5.99-14.55 9.295-24.432 9.295-18.277 0-31.594-11.018-31.594-27.801 0-16.141 12.018-24.009 31.594-24.009s31.595 7.868 31.595 24.009a1.25 1.25 0 1 1-2.5 0c0-14.483-10.733-21.509-29.095-21.509s-29.094 7.026-29.094 21.509c0 15.287 12.104 25.301 29.094 25.301 9.236 0 17.356-3.058 22.632-8.53zM20.38 69.954a1.25 1.25 0 1 1 2.5 0v2.848a1.25 1.25 0 1 1-2.5 0v-2.848zm5.66 6.242a1.25 1.25 0 1 1 0-2.5h35.447a1.25 1.25 0 0 1 0 2.5H26.038z"
            fill={props.color2}
            />
            <path
            d="M7.776 60.46a1.25 1.25 0 1 1-1.163-2.213l3.519-1.85c.917-.483 1.989.296 1.814 1.317l-.562 3.271 2.377 2.317c.742.723.333 1.983-.693 2.132l-3.284.477-1.469 2.976c-.458.93-1.783.93-2.242 0l-1.468-2.976-3.285-.477c-1.025-.15-1.434-1.41-.693-2.132l2.377-2.317-.265-1.544a1.25 1.25 0 0 1 2.464-.423l.376 2.192c.07.406-.065.82-.36 1.107l-1.033 1.007 1.428.207c.408.06.76.315.942.684l.638 1.294.639-1.294a1.25 1.25 0 0 1 .941-.684l1.428-.207-1.033-1.007a1.25 1.25 0 0 1-.36-1.107l.244-1.422-1.277.671zm19.139-30.594a1.25 1.25 0 0 1 1.768 1.768l-6.05 6.049a1.25 1.25 0 1 1-1.767-1.768l6.049-6.049zm59.734 26.23a1.25 1.25 0 0 1 1.727 1.808l-3.513 3.354a1.25 1.25 0 1 1-1.726-1.808l3.512-3.354zm3.657-9.595a1.25 1.25 0 1 1 1.794 1.74l-9.203 9.484a1.25 1.25 0 0 1-1.794-1.742l9.203-9.482zM25.65 26.096a1.25 1.25 0 0 1 1.727 1.808l-3.513 3.354a1.25 1.25 0 1 1-1.726-1.808l3.512-3.354z"
            fill={props.color1}
            />
        </g>
    </svg>
);

export default SvgContact;