import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Spinner from 'react-spinkit';
import FinalField from '../../../lib/FinalField';
import YooCaptcha from '../../../lib/YooCaptcha';
import YooField from "../../../lib/YooField";
import YooForm from '../../../lib/YooForm';
import SvgContact from './icons/SvgContact';
import SvgRocket from './icons/SvgRocket';
import SvgProject from './icons/SvgProject';
import Skeleton from 'react-loading-skeleton';




class App extends Component {
  handleSubmit = (event) => {
    console.log('handleSubmit', event)
  }
  getTerms = (langs) => {
    return (
      <span>{langs.ACCEPT} <a target="_blank" href={langs.TERMSLINK}>{langs.TERMS}</a></span>
    )
  }

  loadingSkeleton() {
    return(
      <div className="sl-form" style = {{ textAlign: 'center' }}>
        <Skeleton height={30} width={'75%'} />
        <div className="group-startup">
          <div className="illustration">
              <div className="picture">
              <Skeleton height={90} width={90}/>
              </div>
            <Skeleton width={90}/>
          </div>
        </div>
        <Container>
          <Row className="idcard">
            <Col md={3}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton className="logo-container" height={178}/>
                </div>
              </div>
            </Col>
            <Col md={9}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form-group">
                <div className="form-group-container">
                <Skeleton height={45} />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  render() {
    const color1 = "#e6106d";
    const color2 = "rgb(31, 127, 238)";
    const textColor = "#939191"
    const bgColor = "#F2F2F2"
    const phColor = "#6c757d"

    const selectStyles = {
      control: (styles, { isFocused }) => {
        return {
          ...styles,
          border: 'none',
          backgroundColor: isFocused
            ? 'white'
            : bgColor,
          padding: '5px 2px',
          borderRadius: '7px',
          boxShadow: isFocused
            ? '0 0 0 0.2rem #bedcff'
            : 'none',
          marginBottom: 0,
          ':hover': {
            ...styles[':hover'],
            boxShadow: '0 0 0 0.2rem #bedcff'
          }
        }
      },
      option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? color1 : isFocused ? color1 : null,
          padding: "2px 10px",
          color: isDisabled
            ? '#ccc'
            : isSelected
              ? 'white'
              : isFocused
                ? 'white'
                  : textColor,
          cursor: isDisabled ? 'not-allowed' : 'pointer',

          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? color1 : null),
          },
        };
      },
      placeholder: (styles) => {
        return {
          ...styles,
          color: phColor
        }
      },
      multiValue: (styles) => {
        return {
          ...styles,
          backgroundColor: color2
        };
      },
      multiValueLabel: (styles) => ({
        ...styles,
        color: 'white'
        // paddingBottom: 0
      }),
      multiValueRemove: (styles) => ({
        ...styles,
        color: 'white',
        ':hover': {
          backgroundColor: color2,
          color: 'white',
        },
      }),
    }

    
    return (
      <YooForm
      loadingComponent={this.loadingSkeleton()}
        render={({ submitError, submitting, fields, langs, bringtoTop, disableSubmit }) => (
          <div className="sl-form">
            {submitting && <div className="overlay">
            <Spinner name="pulse" color="#6c757d" />
              <div className="loader-text">{langs.loaderText}</div>
            </div>
            }
            <h2 className="wow zoomIn" data-wow-delay="0.3s">{langs.formTitle}</h2>

            <div className="group-startup">
              <Row>
                <Col md={12} className="wow zoomIn" data-wow-delay="0.3s">
                  <div className="illustration">
                    <div className="picture">
                      <SvgRocket color1={color1} color2={color2} />
                      <p>{ langs.infosStartup }</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Container>
                <Row className="idcard">
                  <Col md={3} className="wow fadeInLeft">
                    <YooField field="application-picture" className="logo-container" />
                  </Col>
                  <Col md={9} className="wow zoomIn" >
                    <YooField field="application-title" className="wow zoomIn" />
                    <YooField field="application-theme" className="wow zoomIn" styles={selectStyles} />
                    <YooField field="application-links" className="wow zoomIn" />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="wow fadeInUp">
                    <YooField field="application-description" />
                  </Col>
                  <Col md={12} className="wow fadeInUp">
                    <YooField field="application-address" />
                  </Col>
                  <Col md={12} className="wow fadeInUp">
                    <YooField field="pplication-creationdate" />
                  </Col>
                  <Col md={12} className="wow fadeInUp">
                    <YooField field="application-workforce"/>
                  </Col>
                  <Col md={12} className="wow fadeInUp">
                    <YooField field="application-files" />
                  </Col>
                  <Col md={12} className="wow zoomIn">
                    <div className="illustration">
                    <div className="picture">
                      <SvgContact color1={color1} color2={color2} />
                    <p>{ langs.contactStartup }</p>
                    </div>
                    </div>
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    <YooField field="application-contacts.contact-lastname" />
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    <YooField field="application-contacts.contact-firstname" />
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    <YooField field="application-contacts.contact-phone" />
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    <YooField field="application-contacts.contact-email" />
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    <YooField field="application-contacts.contact-position" />
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="1s">
                  <div className="illustration">
                  <div className="picture">
                    <SvgProject color1={color1} color2={color2} />
                  <p>{ langs.projectStartup }</p>
                  </div>
                  </div>
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    <YooField field="application-projectdescription" />
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                  {/* Revoir le lien du PDF pour le reglèment */}
                  <FinalField
                    langs={ langs }
                    label={ (<span>I accept <a target='_blank' href='./pdf/cgu.pdf'>Terms and Conditions</a></span>) }
                    name="rules"
                    type="checkbox"
                    required
                  />
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    <YooCaptcha />
                    {submitError && <div className="helpblock">{submitError}</div>}

                    {fields && (
                      <div className="buttons">
                        <Button
                          type="submit"
                          disabled={submitting || disableSubmit}
                          onClick={bringtoTop}
                          className="button-action button-action-red"
                          variant="primary"
                          size="lg"
                          block
                        >
                        {disableSubmit ? langs.sendingFiles : langs.submitText}
                        </Button>

                      </div>
                    )}
                  </Col>
                </Row>
              </Container>

              </div>
          </div>
        )
        } />
    );
  }
}

export default App;
